import React, { useCallback, useState } from "react"
import {
  Button,
  createStyles,
  FormControl,
  Grid,
  Paper,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { makeStyles } from "@material-ui/core/styles"

interface Inputs {
  min?: string
  max?: string
  numItems?: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    description: {
      padding: theme.spacing(1),
    },
    inputs: {
      marginTop: theme.spacing(1),
    },
  })
)

export default function Sequencer() {
  const [inputs, setInputs] = useState<Inputs>({
    min: "",
    max: "",
    numItems: "",
  })
  const [errors, setErrors] = useState<Inputs>({
    min: "",
    max: "",
    numItems: "",
  })
  const [sequence, setSequence] = useState<number[]>([])

  const { min, max, numItems } = inputs

  const handleInputs = (e: React.SyntheticEvent) => {
    const { name, value } = e.target
    setInputs(prev => {
      return {
        ...prev,
        [name]: parseInt(value.trim()),
      }
    })
  }

  const validate = useCallback(() => {
    const intMin = parseInt(min)
    const intMax = parseInt(max)
    const intNumItems = parseInt(numItems)

    let hasErrors = false

    const errs: { [key: string]: string } = {}
    if (intMin < 0) {
      errs.min = "Must be an integer greater than 0"
      hasErrors = true
    }

    if (intMax <= intMin) {
      errs.max = "Must be an integer greater than the specified low value"
      hasErrors = true
    }

    if (!hasErrors) {
      const range = intMax - intMin + 1
      if (intNumItems == 0 || intNumItems > range) {
        errs.numItems = `Must be an integer greater than 0 and between 1 and ${range}`
      }
    }

    setErrors(errs)
    return Object.keys(errs).length > 0
  }, [min, max, numItems])

  const generate = useCallback(() => {
    if (validate()) {
      return
    }

    const numberSet: number[] = []
    for (let i = parseInt(min); i <= parseInt(max); i++) {
      numberSet.push(i)
    }

    const seq = []
    for (let i = 0; i < parseInt(numItems); i++) {
      const rand = Math.floor(Math.random() * numberSet.length)
      const idx = rand % numberSet.length
      seq.push(numberSet[idx])
      numberSet.splice(idx, 1)
    }

    setSequence(seq)
  }, [min, max, numItems])

  const classes = useStyles()

  return (
    <Layout>
      <SEO
        title="Sequencer"
        description="Generate a randomly ordered sequence of numbers"
      />
      <Typography variant="h2" component="h1" gutterBottom={true}>
        Sequencer
      </Typography>
      <Paper elevation={3} className={classes.description}>
        Generate a sequence of numbers randomly ordered. Specify the low value,
        high value, and the number of items for the sequence.
      </Paper>
      <Grid container spacing={1} className={classes.inputs}>
        <Grid item xs={12} md={3}>
          <TextField
            variant="outlined"
            fullWidth
            type="number"
            name="min"
            label="Low value"
            value={min || ""}
            error={!!errors.min}
            helperText={errors.min}
            onChange={handleInputs}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            variant="outlined"
            fullWidth
            type="number"
            name="max"
            label="High value"
            value={max || ""}
            error={!!errors.max}
            helperText={errors.max}
            onChange={handleInputs}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            variant="outlined"
            fullWidth
            type="number"
            name="numItems"
            label="Number of items"
            value={numItems || ""}
            error={!!errors.numItems}
            helperText={errors.numItems}
            onChange={handleInputs}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl margin="normal">
            <Button variant="contained" color="primary" onClick={generate}>
              Generate Sequence
            </Button>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container>
        <Typography variant="h3">
          {sequence.length > 0 && sequence.join(", ")}
        </Typography>
      </Grid>
    </Layout>
  )
}
